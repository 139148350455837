$(document).ready(function($) {
    if ($.cookie('callbackFloater') !== 'shown') {
        setTimeout( function() {
            $("#callbackFloater").show().addClass("anim");
        }, 10000);
    }
     
    $("#callbackFloater .close").click(function(e) {
        e.preventDefault();
        $.cookie('callbackFloater', 'shown', { expires: 1, path: '/' });
        $(this).parent().fadeOut();
    });
});