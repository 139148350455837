var CaptchaCallback = function() {

	$(document).ready(function($) {
		var options = {
			sitekey: $('#captchaSiteKey').html()
		};

		var a = [
			'#captchaCallback',
			'#captchaBuy',
			'#captchaHire',
			'#captchaContact'
		];

		a.forEach(function(entry) {
			var captcha = $(entry);
			if (captcha.length > 0) {
				//console.log(captcha);
				var widgetId = grecaptcha.render(captcha.get(0), options);
				captcha.data('widgetId', widgetId);
			}
		});
	});
};