$(document).ready(function($) {

	function handleSubmit(submitedForm) {
		var submitedForm = $(submitedForm);
		var submit = submitedForm.find('input[type="submit"]');
		var msg = submitedForm.find('.message');
		var captcha = submitedForm.find('.g-recaptcha');

		if (submitedForm.data('submitted') === true) {
			e.preventDefault();
		} else {
			submitedForm.data('submitted', true);
			submit.attr('disabled','disabled');

			msg.slideUp();

			$.post( submitedForm.attr('action'), submitedForm.serializeArray())
				.always( function() {
					submitedForm.data('submitted', false);
					submit.removeAttr('disabled');
				})
				.done( function( data ) {
					msg.html( data.msg );

					if ( data.status == 'success' ) {
						submitedForm
							.find(':input').not(':button, :submit, :reset, :hidden')
							.val('').removeAttr('checked').removeAttr('selected');

						msg.removeClass('error').addClass('success');
					} else {
						msg.removeClass('success').addClass('error');
					}
				})
				.fail(function(jqXHR, textStatus) {
					msg.html( textStatus ).removeClass('success').addClass('error');
				})
				.always( function(data) {
					msg.slideDown();
					if (data.resetCaptcha == true && captcha.length > 0) {
						grecaptcha.reset(captcha.data('widgetId'));
					}
				});
		}
	}

	function handleForm(form) {
		form.on("click", ".message", function(e) { $(this).slideUp(); }).find('.message').hide();

		form.submit(function(e) { e.preventDefault(); })
			.validate({
				errorPlacement: function(error, el) {},
				submitHandler: handleSubmit
			});
	}

	handleForm( $("#newsletter-form") );
	handleForm( $("#callback-form") );
	handleForm( $("#contact-form") );
	handleForm( $("#hire-form") );
	handleForm( $("#buy-form") );
});

$(document).ready(function($) {
	$("form#searchForm").validate({ errorPlacement: function(error, element) {} });
});