$(document).ready(function($) {
	var headerWrapper = $('#headerWrapper');
	var headerHeight = headerWrapper.height();
	var headerHeightFull = headerWrapper.outerHeight(true);
	var contentWrapper = $('.contentWrapper').first();

	/*var stickyMenuUpdate = function() {
		if ($(document).scrollTop() > headerHeight) {
			contentWrapper.css('paddingTop', headerHeightFull);
			headerWrapper.addClass('sticky');
		} else {
			contentWrapper.css('paddingTop', 0);
			headerWrapper.removeClass('sticky');
		}
	};*/
	/*stickyMenuUpdate();
	$(window).scroll( $.throttle(100, stickyMenuUpdate) );*/
});

$(document).ready(function($) {
	$('#userMenuLink a').click( function(e) {}); //fix for user menu
});

$(document).ready(function($) {
	var questions = $('#questions div');
	var answers = $('#answers div');

	questions.find('a').click(function(e) {
		e.preventDefault();
		questions.hide();
		$( $(this).attr('href') ).show();
	});
});

/*$(".hamburgerMenu").click(function() {
    $("#headerWrapper header nav").slideDown();
    $(".hamburgerMenu").hide();
    $(".closeMenu").show()
})

$(".closeMenu").click(function() {
    $("#headerWrapper header nav").slideUp();
    $(".hamburgerMenu").show();
    $(".closeMenu").hide()
})*/

                  
$(function() {
    $('#menuSlick').slicknav();
});
