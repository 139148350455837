(function($) {
"use strict";

$.fn.cycle.transitions.scrollHorz2d = {
    before: function( opts, curr, next, fwd ) {
    	opts.API.stackSlides( curr, next, fwd );
        var w = opts.container.css('overflow','hidden').width();        	
    	
        opts.cssBefore = {
        	transform: 'translateX(' + (fwd ? w : - w) + 'px)',
        	//left: fwd ? w : - w,
        	top: 0,
        	//left: 'auto',
        	opacity: 1,
        	display: 'block',
        	position: 'absolute',
        	visibility: 'visible'
        };
        opts.cssAfter = {
        	zIndex: opts._maxZ - 2,
        	transform: 'translateX(0px)'
        };
        opts.animIn = {
        	transform: 'translateX(0px)'
        };
        opts.animOut = {
        	transform: 'translateX(' + (fwd ? -w : w) + 'px)'
        };
	}
};
})(jQuery);