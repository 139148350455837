$(document).ready( function($) {
	var cookie = $('#cookieWarning');
	var footer = $('footer');

	if ($.cookie('cookies') == 'accepted') {
		cookie.hide(0);
		footer.removeClass('cookieShown');
	}

	cookie.find('.close').click( function(e) {
		e.preventDefault();
		$.cookie('cookies', 'accepted', { expires: 7, path: '/' });
		cookie.slideUp( function() {
			footer.removeClass('cookieShown');
		});
	});
});